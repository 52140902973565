export const environment = {
  production: false,
  baseURL: 'https://api.jvgroup.co.in/index.php/',
  mediaURL: 'https://api.jvgroup.co.in/uploads/',
  bannerURL: 'https://jvgroup.co.in/uploads/',

  onesignal: {
    appId: '',
    googleProjectNumber: '',
    restKey: ''
  },
  authToken: 'aZVYsecret_OfAtleast32CharactRFC',
  app_name: 'jvgroup.co.in',
  websiteURL: 'https://jvgroup.co.in',
  websiteName: 'Jv Group',
  default_country_code: '91'
};
